<template>
  <div class="modal fade" ref="CmpModalFiltrosListadoFacturas" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Filtros
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Da click sobre el filtro que desas utilizar en el listado de facturas:
          </p>
          <div>
            <div class="form-check form-switch mb-3 form-switch-lg" style="display: inline-block;">
              <input
                class="form-check-input"
                type="checkbox"
                id="swNoPagadas"
                v-model="no_pagadas"
                @change="no_pagadas ? agregarfiltro('no pagadas') : quitarfiltro('no pagadas')"
              />
              <label class="form-check-label" for="swNoPagadas">Sin pagar</label>
            </div>
            &nbsp; &nbsp; 
            <div class="form-check form-switch mb-3 form-switch-lg" style="display: inline-block;">
              <input
                class="form-check-input"
                type="checkbox"
                id="swPagadas"
                v-model="pagadas"
                @change="pagadas ? agregarfiltro('pagadas') : quitarfiltro('pagadas')"
              />
              <label class="form-check-label" for="swPagadas">Pagadas</label>
            </div>
            &nbsp; &nbsp; 
            <div class="form-check form-switch mb-3 form-switch-lg" style="display: inline-block;">
              <input
                class="form-check-input"
                type="checkbox"
                id="swCanceladas"
                v-model="canceladas"
                @change="canceladas ? agregarfiltro('canceladas') : quitarfiltro('canceladas')"
              />
              <label class="form-check-label" for="swCanceladas">Canceladas</label>
            </div>
            &nbsp; &nbsp; 
            <div class="form-check form-switch mb-3 form-switch-lg" style="display: inline-block;">
              <input
                class="form-check-input"
                type="checkbox"
                id="swSistema"
                v-model="sistema"
                @change="sistema ? agregarfiltro('sistema') : quitarfiltro('sistema')"
              >
              <label class="form-check-label" for="swSistema">Generadas por sistema</label>
            </div>
            &nbsp; &nbsp; 
            <div class="form-check form-switch mb-3 form-switch-lg" style="display: inline-block;">
              <input
                class="form-check-input"
                type="checkbox"
                id="swManuales"
                v-model="manuales"
                @change="manuales ? agregarfiltro('manuales') : quitarfiltro('manuales')"
              >
              <label class="form-check-label" for="swManuales">Generadas manualmente</label>
            </div>
            &nbsp; &nbsp; 
            <div class="form-check form-switch mb-3 form-switch-lg" style="display: inline-block;">
              <input
                class="form-check-input"
                type="checkbox"
                id="swProrrogas"
                v-model="prorrogas"
                @change="prorrogas ? agregarfiltro('prorrogas') : quitarfiltro('prorrogas')"
              >
              <label class="form-check-label" for="swProrrogas">Promesa de pago</label>
            </div>
          </div>

          <br>

          <div class="row">
            <div class="col-md-4">
              <div class="input-group">
                <span class="input-group-text">
                  Fecha para pago
                </span>
                <input
                  type="date"
                  class="form-control"
                  name="fecha_pago"
                  placeholder="DD/MM/YYYY"
                  v-model="fecha_pago"
                  @change="filtrosActualizados()"
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="input-group mt-1">
                <span class="input-group-text">
                  Sitio
                </span>
                <select v-model="id_sitio" class="form-select" @change="filtrosActualizados()">
                  <option :value="null">Todos</option>
                  <option
                    :value="sitio.id"
                    v-for="sitio in sitios"
                    :key="sitio.id"
                  >
                    {{ sitio.nombre }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SitioSrv from '@/services/SitioSrv.js'
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'CmpModalFiltrosListadoFacturas',
  data() {
    return {
      fecha_pago: '',
      id_sitio: null,
      no_pagadas: true,
      pagadas: false,
      canceladas: false,
      vencidas: false,
      sistema: false,
      manuales: false,
      prorrogas: false,
      filtros: [],
      sitios: []
    }
  },
  methods: {
    agregarfiltro(filtro){
      var self = this
      self.filtros.push(filtro)
      self.filtrosActualizados()
    },
    cargarSitios: function() {
      var self = this

      SitioSrv.sitiosJSON().then(response => {
        self.sitios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los sitios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    filtrosActualizados: function() {
      var self = this,
      datos = {
        filtros: self.filtros,
        fecha_pago: self.fecha_pago,
        id_sitio: self.id_sitio
      }

      self.$emit('filtros-actualizados', datos)
    },
    quitarfiltro(filtro){
      var self = this 
      self.filtros.splice(self.filtros.indexOf(filtro), 1)
      self.filtrosActualizados()
    },
    mostrar(params) {
      var self = this

      self.filtros = params.filtros

      self.filtros.indexOf('no pagadas') >= 0 ? self.no_pagadas = true : self.no_pagadas = false
      self.filtros.indexOf('pagadas') >= 0 ? self.pagadas = true : self.pagadas = false
      self.filtros.indexOf('canceladas') >= 0 ? self.canceladas = true : self.canceladas = false
      self.filtros.indexOf('vencidas') >= 0 ? self.vencidas = true : self.vencidas = false
      self.filtros.indexOf('sistema') >= 0 ? self.sistema = true : self.sistema = false
      self.filtros.indexOf('manuales') >= 0 ? self.manuales = true : self.manuales = false
      self.filtros.indexOf('prorrogas') >= 0 ? self.prorrogas = true : self.prorrogas = false

      self.fecha_pago = params.fecha_pago
      self.id_sitio = params.id_sitio

      self.cargarSitios()

      var modal = new Modal(this.$refs.CmpModalFiltrosListadoFacturas)
      modal.show()
    }
  },
}
</script>
